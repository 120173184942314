$height: 45px
$width: 90px

label
  width: $width
  height: $height
  position: relative
  display: block
  background: #ebebeb
  border-radius: 200px
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4)
  cursor: pointer
  transition: 0.3s

  &:after
    content: ""
    width: calc($height - 8px)
    height: calc($height - 8px)
    position: absolute
    top: 4px
    left: 4px
    background: linear-gradient(180deg, #ffcc89, #d8860b)
    border-radius: 180px
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2)
    transition: 0.3s

input
  width: 0
  height: 0
  display: none

  &:checked + label
    background: #242424

    &:after
      left: calc($width - 4px)
      transform: translateX(-100%)
      background: linear-gradient(180deg, #777, #3a3a3a)

label:active:after
  width: calc($height + ($height / 2))

.background
  width: 100vw
  height: 100vh
  background: #fff
  z-index: -1
  position: absolute
  transition: 0.3s

input:checked + label + .background
  background: #242424

label svg
  position: absolute
  width: calc(($height - 8px)*0.8)
  top: calc($height/2 - ($height - 8px)*0.8/2)
  z-index: 100

  &.sun
    left: calc($height/2 - ($height - 8px)*0.8/2)
    fill: #fff
    transition: 0.3s

  &.moon
    right: calc($height/2 - ($height - 8px)*0.8/2)
    fill: #7e7e7e
    transition: 0.3s

input:checked + label svg
  &.sun
    fill: #7e7e7e

  &.moon
    fill: #fff
