@tailwind base
@tailwind components
@tailwind utilities

@font-face
  font-family: 'Archivo Black'
  src: url("fonts/Archivo-Black.woff2")

@font-face
  font-family: 'Archivo Light'
  src: url("fonts/Archivo-Light.woff2")

@font-face
  font-family: 'Archivo Regular'
  src: url("fonts/Archivo-Regular.woff2")


html,
#root,
body
  margin: 0
  padding: 0
  height: 100%
  font-family: 'Archivo Light', serifs

.font-face-black
  font-family: 'Archivo Black', serifs

*
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out

.line-clamp
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow: hidden

.behindeffect
  position: relative
  width: 100%
  height: 100%
  z-index: 10
  &:after
    z-index: 0
    content: attr(data-text)
    position: absolute
    font-size: 120px
    top: 0
    left: 0
    transform: translate(calc(-40% - 200px), -20%)
    color: #FFF3
    opacity: 0
    transition: all 0.3s ease
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2)
    text-transform: uppercase

  &:hover
    &:after
      transform: translate(calc(-30%), -20%)
      opacity: 1

